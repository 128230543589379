import React, { useState, useEffect } from "react"
import "./_header.scss"
import { StaticImage } from "gatsby-plugin-image"
import { useMenuSections } from "../../hooks"
import Menu from "./Navbar/menu.component"
import ExternalServices from "./externalServices.component"
import EnrollForm from "./enroll.component"
import { useWindowSize } from "../../hooks/"
import { Icon } from ".."

const Header = ({ handleScroll, active, handleLocation }) => {
  const dimensions = useWindowSize()
  const [isLoginShown, setIsLoginShown] = useState(false)
  const [isEnrollShown, setIsEnrollShown] = useState(false)
  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isActive, setIsActive] = useState(active)
  const menu = useMenuSections()
  const menuList = menu.allStrapiMenuSections.nodes

  useEffect(() => {
    setIsActive(false)
    handleScroll(false)
  }, [dimensions]) // eslint-disable-line react-hooks/exhaustive-deps

  const rotateLogin = isLoginShown ? "rotate" : ""
  const rotateEnroll = isEnrollShown ? "rotate" : ""
  const activeMenu = active ? "active" : ""
  const enroll = isEnrollShown && (
    <div
      role="button"
      tabIndex="0"
      onMouseLeave={() => setIsEnrollShown(false)}
    >
      <EnrollForm />
    </div>
  )
  const login = isLoginShown && (
    <div role="button" tabIndex="0" onMouseLeave={() => setIsLoginShown(false)}>
      <ExternalServices />
    </div>
  )
  const overlayActive = () => {
    return isLoginShown || isEnrollShown || isMenuShown
  }
  const activeTrue = () => {
    handleScroll(true)
  }
  const activeFalse = () => {
    handleScroll(false)
  }

  const handleOverlay = () => {
    setIsMenuShown(false)
    active = false
    activeFalse()
  }
  const overlay = overlayActive() && !isActive && (
    <div className="overlay-header"></div>
  )
  // const overlayHeader = isActive && <div className="overlay-header"></div>
  const closeMenu = active && (
    <div>
      <div
        role="button"
        tabIndex="0"
        aria-labelledby="close"
        className="overlay-sidebar d-lg-none"
        onClick={activeFalse}
        onKeyDown={activeFalse}
      ></div>
      <button id="close" className="navbar__close" onClick={activeFalse}>
        <Icon type={"fas"} code={"times"} />
      </button>
    </div>
  )

  const menuSections = menuList.map((section, index) => (
    <Menu
      key={index}
      section={section}
      active={active}
      handleLocation={handleLocation}
      handleOverlay={handleOverlay}
    />
  ))

  return (
    <header className="header" id="top">
      {overlay}
      <nav className="navbar">
        <div className="container px-3 px-md-0">
          <button className="navbar__button" onClick={activeTrue}>
            <Icon type={"fas"} code={"bars"} />
          </button>
          <div className="navbar__brand">
            <a className="" href="/">
              <StaticImage
                src="../../images/logo-siglo.png"
                width={112}
                quality={100}
                layout="fixed"
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="siteTitle"
              />
            </a>
          </div>
          {/* {overlayHeader} */}
          {closeMenu}
          <div
            role="button"
            tabIndex="0"
            className={`navbar__menu ${activeMenu}`}
            onMouseEnter={() => {
              setIsLoginShown(false)
              setIsEnrollShown(false)
              setIsMenuShown(true)
            }}
            onMouseLeave={() => setIsMenuShown(false)}
          >
            <ul>{menuSections}</ul>
          </div>
          <div className="navbar__buttons">
            <button
              onClick={() => {
                setIsLoginShown(!isLoginShown)
                setIsEnrollShown(false)
              }}
              className={`button-green ${rotateLogin}`}
            >
              <Icon type={"far"} code={"user"} />
              <p>Soy Estudiante</p>
              <Icon type={"fas"} code={"angle-down"} />
            </button>
            {login}
            <button
              onClick={() => {
                setIsEnrollShown(!isEnrollShown)
                setIsLoginShown(false)
              }}
              className={`button button-green ${rotateEnroll}`}
            >
              <Icon type={"fas"} code={"search"} />
              <p>Buscá tu Carrera</p>

              <Icon type={"fas"} code={"angle-down"} />
            </button>
            {enroll}
            <a
              href="https://www.21.edu.ar/oferta-academica"
              className="button-yellow button-yellow-menu"
            >
              <Icon type={"fas"} code={"shopping-cart"} />
              <p>Inscribite Ahora</p>
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Header
